<template>
  <div>
    <v-card color="transparent" flat>
      <v-card-title>
        Adicionar Token de Acesso
      </v-card-title>
      <v-card v-show="form.access_token" color="transparent" flat>
        <v-card>
          <v-card-title>
            Token de Acesso Gerado com sucesso
          </v-card-title>
          <v-card-text>
            Tenha certeza de copiar o código do seu Token de Acesso agora, pois você não poderá acessa-lo novamente.
          </v-card-text>
          <v-card-text>
            <v-card outlined tile>
              <v-card-text>
                {{ form.access_token }}
              </v-card-text>
              <v-card-actions>
                <v-btn class="ml-2" color="primary" small @click="copyAccessTokenToClipboard">
                  <v-icon left>{{ !form.access_token_copied ? 'mdi-content-copy' : 'mdi-check'}}</v-icon>
                  {{ !form.access_token_copied ? 'Copiar' : 'Copiado'}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card-actions
            class="px-0 my-2"
        >
          <v-btn
              block
              color="primary"
              large
              @click="$router.push('/configuracoes/sistema/tokens-acesso')"
          >
            Voltar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-show="!form.access_token" color="transparent" flat>
        <v-card :disabled="form.processing">
          <v-card-text>
            Preencha os campos abaixo e clique no botão salvar.
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="form.name"
                    dense
                    hide-details="auto"
                    label="Descrição *"
                    outlined
                    persistent-placeholder
                    :error-messages="form.errors.name"
                    @input="delete form.errors.name"
                />
              </v-col>
              <v-col cols="12">
                <usuarios-agricola-select
                    v-model="form.user_id"
                    hide-details="auto"
                    :error-messages="form.errors.user_id"
                    @input="delete form.errors.user_id"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="form.expiration_time"
                    dense
                    hide-details="auto"
                    label="Expiração *"
                    outlined
                    persistent-placeholder
                    :items="selects.expirationTime"
                    :error-messages="form.errors.expiration_time"
                    @input="function () {
                      delete form.errors.expiration_time
                      if (newValue !== 'custom') {
                        form.expires_at = null
                        delete form.errors.expires_at
                      }
                    }"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                    v-model="form.expires_at"
                    v-mask="'##/##/####'"
                    dense
                    :disabled="form.expiration_time !== 'custom'"
                    hide-details="auto"
                    :label="'Data de Expiração' + (form.expiration_time !== 'custom' ? '' : ' *')"
                    outlined
                    persistent-placeholder
                    placeholder="dd/mm/YYYY"
                    :error-messages="form.errors.expires_at"
                    @input="delete form.errors.expires_at"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-actions
            class="px-0 my-2"
        >
          <v-btn
              block
              color="primary"
              large
              :loading="form.processing"
              @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from '@/core/services/api.service'
import { VueMaskDirective } from 'v-mask'

export default {
  directives: { VueMaskDirective },
  data: () => ({
    form: {
      user_id: null,
      name: null,
      expiration_time: null,
      expires_at: null,
      processing: false,
      access_token: null,
      access_token_copied: false,
      errors: [],
    },

    selects: {
      expirationTime: [{
        text: '7 Dias', value: 7
      }, {
        text: '30 Dias', value: 30
      }, {
        text: '60 Dias', value: 60
      }, {
        text: '90 Dias', value: 90
      }, {
        text: 'Não Expira', value: 'never'
      }, {
        text: 'Personalizado', value: 'custom'
      }]
    },
  }),

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Configurações' },
      { title: 'Sistema' },
      { title: 'Tokens de Acesso', route: '/configuracoes/sistema/tokens-acesso' },
      { title: 'Adicionar' },
    ])
  },

  methods: {
    async save () {
      this.form.errors = []

      this.form.processing = true

      try {
        const result = (await ApiService.post('tokens', this.form)).data

        this.form.access_token = 'Bearer ' + result.access_token
        this.$refs.snackbar.show(
            'Deu tudo certo!',
            'Requisição atendida com sucesso.',
        )
      } catch (e) {
        if (e.response) {
          if (e.response.data.errors) {
            this.form.errors = e.response.data.errors
          }

          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              e.response.data.message,
              'danger',
          )
        } else {
          this.$refs.snackbar.show(
              'Desculpe, algo deu errado!',
              'Não foi possível atender a sua requisição.',
              'danger',
          )
        }
      }

      this.form.processing = false
    },

    async copyAccessTokenToClipboard () {
      try {
        await navigator.clipboard.writeText(this.form.access_token);

        this.form.access_token_copied = true

        const sleep = ms => new Promise(res => setTimeout(res, ms));

        await sleep(3000)

        this.form.access_token_copied = false
      } catch (err) {
        console.log(err)
        this.$refs.snackbar.show(
            'Desculpe, algo deu errado!',
            'Copie o Token de acesso manualmente.',
            'danger',
        )
      }
    }
  },
}
</script>